import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(public authService: AuthService) {}

  canActivate(): boolean {
    return this.authService.isAuthenticated();
  }
}
