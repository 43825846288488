// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/image-modal/image-modal.page.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,iBAAA;AACF","sourcesContent":["img {\r\n  width: 100%;\r\n  height: 320px;\r\n  object-fit: cover;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
