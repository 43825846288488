import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AppService} from '../../services/app.service';

@Component({
    selector: 'app-image-modal',
    templateUrl: './image-modal.page.html',
    styleUrls: ['./image-modal.page.scss'],
    standalone: false
})
export class ImageModalPage implements OnInit {
  @Input() images = [];

  constructor(private modalCtrl: ModalController, public appService: AppService) {}

  ngOnInit() {}

  dismissModal() {
    this.modalCtrl.dismiss();
  }
}
