// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --border-width: 0 !important;
}

ion-menu {
  --max-width: 280px !important;
}

.menu-holder {
  padding-left: 20px;
  padding-right: 20px;
}
.menu-holder ion-item {
  --padding-start: 0px !important;
}
.menu-holder ion-item ion-label {
  --color: #000000;
  font-size: 19px;
  font-family: myriadpro-regular !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AACC;EACE,6BAAA;AAEH;;AACA;EACE,kBAAA;EACA,mBAAA;AAEF;AAAK;EACE,+BAAA;AAEP;AAAO;EACE,gBAAA;EACA,eAAA;EACA,yCAAA;AAET","sourcesContent":["ion-toolbar {\n  --border-width: 0 !important;\n}\n ion-menu {\n   --max-width: 280px !important;\n\n }\n.menu-holder {\n  padding-left: 20px;\n  padding-right: 20px;\n \n     ion-item {\n       --padding-start: 0px !important;\n  \n       ion-label {\n         --color: #000000;\n         font-size: 19px;\n         font-family: myriadpro-regular !important;\n  \n       }\n     }\n   \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
