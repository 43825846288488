// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-input {
  border: 1px solid gray;
  border-radius: 6px;
  padding: 4px !important;
  margin: 5px 30px;
  width: 85%;
}

.nextbtn {
  position: absolute;
  bottom: -61px;
  right: 31px;
}

ion-button .next-contant {
  margin-left: 15px;
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/buildquotelogin/build-quotelogin.page.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,kBAAA;EACA,uBAAA;EACA,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,kBAAA;EACA,aAAA;EACA,WAAA;AACJ;;AAGI;EACI,iBAAA;EACA,kBAAA;AAAR","sourcesContent":["ion-input {\n    border: 1px solid gray;\n    border-radius: 6px;\n    padding: 4px !important;\n    margin: 5px 30px;\n    width: 85%;\n}\n\n.nextbtn {\n    position: absolute;\n    bottom: -61px;\n    right: 31px;\n}\n\nion-button {\n    .next-contant {\n        margin-left: 15px;\n        margin-right: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
