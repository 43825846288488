import {Component} from '@angular/core';
import {
  AlertController,
  MenuController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import {Router} from '@angular/router';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {AuthService} from './services/auth.service';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {AppService} from './services/app.service';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {WelcomePage} from '../app/pages/welcome/welcome.page';
import {Location} from '@angular/common';
import {StorageService} from './services/storage.service';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {OrderCompletedComponent} from './components/order-completed/order-completed.component';
import {BuildQuoteloginPage} from './pages/buildquotelogin/build-quotelogin.page';

import {FirebaseDynamicLinks} from '@ionic-native/firebase-dynamic-links/ngx';
import * as crashlytics from '@ionic-native/firebase-crashlytics';
import {LoggerService} from './services/logger.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: false
})
export class AppComponent {
  step: number;
  token: any;
  handlerMessage = '';
  roleMessage = '';
  user_group = 3;
  user: any = {};

  constructor(
    private platform: Platform,
    private location: Location,
    private menu: MenuController,
    private navCtrl: NavController,
    private modalController: ModalController,
    private router: Router,
    private iab: InAppBrowser,
    private alertCtrl: AlertController,
    private deeplinks: Deeplinks,
    private storage: StorageService,
    private app: AppService,
    private authService: AuthService,
    private splashScreen: SplashScreen,
    private firebaseDynamicLinks: FirebaseDynamicLinks,
    private androidPermissions: AndroidPermissions,
    private loggerService: LoggerService
  ) {}

  ngOnInit() {
    this.initializeApp();
    this.backbtn();
    window.onerror = (error: any) => {
      this.loggerService.logErrorAndAlert(error);
    };
  }

  setupFirebaseDynamicLinks() {
    this.firebaseDynamicLinks.onDynamicLink().subscribe((data) => {
      alert('deeplink');
      // if (data.deepLink === 'yourapp://buildquotelogin') {
      // Import NavController in your component
      // import { NavController } from '@ionic/angular';

      // Navigate to the desired page
      this.navCtrl.navigateForward('/buildquotelogin');
      // }
    });
  }

  initializeApp() {
    try {
      this.platform.ready().then(async () => {
        this.splashScreen.hide();
        await this.storage.init();
        await this.authService.getUserAsync();
        this.checkAndRedirect();
        this.authService.authenticationState.subscribe((isAuthenticated) => {
          const currentUrl = window.location.href;

          if (
            currentUrl.includes('/buildquotelogin') &&
            !currentUrl.includes('buildquotelogin;data=')
          ) {
            const match = currentUrl.match(/\/buildquotelogin(.*)/);

            if (match) {
              const baseUrl = match[0];
              const queryParams = match[1];
              this.router.navigate(['/buildquotelogin', {data: queryParams}]);
            } else {
            }
          } else {
            if (isAuthenticated) {
              this.authService.userInfo.subscribe((userRes) => {
                this.user = userRes;
                this.user_group = this.user.user_group;
              });
              this.authService.oneSignalInit();
              this.authService.invoiceId.subscribe((invoceId: any) => {
                if (invoceId) {
                  this.navCtrl.navigateForward(['/tabs/alerts/repair-estimate', invoceId]);
                } else {
                  this.router.navigate(['tabs/home']);
                }
              });

              // this.router.navigate(['tabs/home']);
              this.setupDeeplinks();
            } else {
              this.router.navigate(['/splashscreen']);
            }
          }
        });
        this.setupDeeplinks();
        crashlytics.FirebaseCrashlytics.initialise();
        this.checkIsJobComplete();
      });
    } catch (error) {
      this.loggerService.logError(`initializeApp error: ${error.message}`);
    }
  }

  checkAndRedirect() {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/buildquotelogin')) {
      this.router.navigate(['/buildquotelogin', {data: currentUrl}]);
    }
  }

  setupDeeplinks() {
    this.deeplinks
      .route({
        '/buildquotelogin': BuildQuoteloginPage,
      })
      .subscribe(
        (match) => {
          this.setupFirebaseDynamicLinks();
          // this.router.navigateByUrl(match.$link.url);
          this.router.navigate(['/buildquotelogin', {data: match.$link.queryString}]);
        },
        (nomatch) => {
          // Handle the case where no match is found
        }
      );
  }

  async checkIsJobComplete() {
    this.authService.consumerNotifications().subscribe(
      async (res: any) => {
        // this.loading = false;
        // this.app.dismissLoader();
        if (res.data[0].job_status == '4' && res.data[0].read == '0') {
          const orderCompleted = await this.modalController.create({
            component: OrderCompletedComponent,
            cssClass: 'order-completed-modal',
            initialBreakpoint: 0.2,
            componentProps: {order: res.data[0]},
          });
          orderCompleted.present();
        }
        // this.notifications = res.data;
        // this.markReadNotification();
      },
      (error) => {
        // this.loading = false;
        // this.app.dismissLoader();
      }
    );
  }

  ngAfterViewInit() {
    this.deeplinks
      .routeWithNavController(this.navCtrl, {
        '/welcome/:id': WelcomePage,
        // '/products/:productId': ProductPage
      })
      .subscribe(
        (match) => {
          // match.$route - the route we matched, which is the matched entry from the arguments to route()
          // match.$args - the args passed in the link
          // match.$link - the full link data
          var paths = match.$link.path;
        },
        (nomatch) => {
          // nomatch.$link - the full link data
          console.log("Got a deeplink that didn't match", nomatch);
        }
      );
  }

  checkAndRequestCameraPermission() {
    try {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        (result) => {
          if (result.hasPermission) {
            // Camera permission is granted
          } else {
            // Camera permission is not granted; request it
            this.androidPermissions
              .requestPermissions([
                this.androidPermissions.PERMISSION.CAMERA,
                this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
                this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
                this.androidPermissions.PERMISSION.READ_MEDIA_IMAGES,
              ])
              .then(
                (success) => {
                  if (success.hasPermission) {
                    // Camera permission has been granted
                  } else {
                    // Camera permission request was denied
                    console.log('Camera permission denied.');
                  }
                },
                (err) => {
                  // Error occurred while requesting permission
                  this.loggerService.logError(`Error requesting camera permission: ${err}`);
                }
              );
          }
        },
        (err) => {
          // Error occurred while checking permission
          this.loggerService.logError(`Error checking camera permission: ${err}`);
        }
      );
    } catch (error) {
      this.loggerService.logError(`Camera PermissionseError ${error}`);
    }
  }

  checkAndRequestStoragePermission() {}
  openLink(link: string) {
    this.menu.close('first');

    if (link === 'shop') this.iab.create(' https://automonkey.ourproshop.com/');
    else {
      const url = `https://theautomonkey.com/${link}`;
      this.iab.create(url);
    }
  }
  aboutAutomonkey() {
    this.menu.close('first');
    const url = `https://youtu.be/UZ50KHKsN7Y`;
    this.iab.create(url);
  }

  notificationSettings() {
    this.menu.close('first');
    this.navCtrl.navigateForward('/tabs/profile/notification-settings');
  }
  appReview() {}

  shareApp() {}
  logout() {
    this.app.presentLoader();
    this.authService.logout().subscribe(
      (res: any) => {
        this.app.dismissLoader();
        this.storage.remove('is_verification_pending');
        this.authService.storage.remove('token');
        this.authService.storage.remove('newtoken');
        this.authService.storage.remove('user');
        this.authService.storage.remove('user_info');
        this.authService.storage.remove('MECHANIC_COUNT');
        this.authService.storage.remove('USER_GROUP');
        this.storage.remove('idget');
        this.storage.remove('IS_FROM_SERVEY');
        this.storage.remove('reduced_user');
        this.authService.invoiceId.next(null);
        localStorage.clear();

        this.authService.authenticationState.next(false);
        this.authService.authUser = undefined;
        this.menu.close('first');
        this.router.navigate(['/welcome']);
      },
      (error) => {
        this.app.dismissLoader();
      }
    );

    // this.authService.logout();
    // this.menu.close('first');
    // this.router.navigate(['/welcome']);
  }

  async deActivateAccount() {
    const alert = await this.alertCtrl.create({
      header: 'Are you sure, you want to deactivate your account?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            this.handlerMessage = 'Alert canceled';
          },
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            this.handlerMessage = 'Alert confirmed';
            this.storage.get('token').then((res) => {
              this.token = res;

              this.app.presentLoader();
              this.authService.deActivateAccount(this.token).subscribe(
                (res: any) => {
                  this.app.dismissLoader();
                  if (res && res.status) {
                    this.authService.storage.remove('token');
                    this.authService.storage.remove('newtoken');
                    this.app.presentToast(res.message);
                    this.step = 1;
                    this.menu.close('first');
                    this.navCtrl.navigateBack('login', {animated: false});
                  } else {
                    this.app.presentErrorToast(res.message);
                  }
                },
                (error) => {
                  this.app.dismissLoader();
                  this.app.presentErrorToast('Oops... Something went wrong. Please try again.');
                }
              );
            });
          },
        },
      ],
    });

    await alert.present();

    const {role} = await alert.onDidDismiss();
    this.roleMessage = `Dismissed with role: ${role}`;
  }

  backbtn() {
    this.platform.backButton.subscribe(async () => {
      if (this.router.isActive('/tabs/home', true)) {
        this.app.dismissLoader();
        navigator['app'].exitApp();
      } else {
        this.app.dismissLoader();
        this.location.back();
      }
    });
  }
}
